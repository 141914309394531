import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const BlockRenderer = (props) => {
	// Fall back to default handling
	return BlockContent.defaultSerializers.types.block(props);
};

const Serializers = {
	types: {
		block: BlockRenderer,
	},
	marks: {
		link: (props) => (
			<a rel="noreferrer" href={props.mark.href} target="_blank">
				<span>
					<FontAwesomeIcon icon={faExternalLinkAlt} />
					&nbsp;
				</span>
				{props.children}
			</a>
		),
	},
};

export default Serializers;
