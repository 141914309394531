import * as React from 'react';
import PortableText from './PortableText';

const Main = ({
	title,
	description,
	information: { size = {}, medium, miscellaneous, year },
	fromIndex,
}) => {
	return (
		<main id="main">
			{fromIndex ? <h2>{title}</h2> : <h2>Essence of {title}</h2>}
			<PortableText blocks={description} />
			{!fromIndex && (
				<p className="info">
					{size && size}
					{size && ', '}
					{medium && medium}
					{medium && ', '}
					{miscellaneous && miscellaneous}
					{miscellaneous && ', '}
					{year && year}
				</p>
			)}
		</main>
	);
};

export default Main;
