import React, { useEffect, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const HeroImage = ({
	image,
	allSanityArtwork,
	location,
	alt = {},
	fromIndex,
}) => {
	const { state = {} } = location;
	//const { fromTranslation = {} } = state;
	//console.log(location);
	//const [heroVisibility, setHeroVisibility] = useState(true);
	/* const [heroVisibility, setHeroVisibility] = useState(
		location.state === !null || location.state.fromTranslation ? false : true
	); */
	const [heroVisibility, setHeroVisibility] = useState(
		state === null || undefined ? true : state.fromTranslation ? false : true
	);
	/* const [heroVisibility, setHeroVisibility] = useState(
		fromTranslation ? false : true
	); */

	const isVisible = heroVisibility ? 'heroBackground' : 'heroHidden';
	const isStackedVisible = heroVisibility
		? 'heroStackedBackground'
		: 'heroStackedBackgroundHidden';

	useEffect(() => {
		var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
		let counter = 0;

		function preventDefaultForScrollKeys(e) {
			if (keys[e.keyCode]) {
				preventDefault(e);
				return false;
			}
		}

		// modern Chrome requires { passive: false } when adding event
		var supportsPassive = false;
		try {
			window.addEventListener(
				'test',
				null,
				Object.defineProperty({}, 'passive', {
					get: function () {
						supportsPassive = true;
					},
				})
			);
		} catch (e) {}

		var wheelOpt = supportsPassive ? { passive: false } : false;
		var wheelEvent =
			'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

		function preventDefault(e) {
			/* if (location.state.fromTranslation) {
				return;
			} */
			if (!heroVisibility) {
				return;
			}
			e.preventDefault();
			e.stopPropagation();
			setHeroVisibility(false);
			counter = counter + 1;
			//console.log(counter);
			if (counter === 1) {
				setTimeout(function () {
					//console.log('scroll unlocked');
					window.removeEventListener('DOMMouseScroll', preventDefault, false);
					window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
					window.removeEventListener('touchmove', preventDefault, wheelOpt);
					window.removeEventListener(
						'keydown',
						preventDefaultForScrollKeys,
						false
					);
				}, 600);
			}
		}

		window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
		window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
		window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
		window.addEventListener('keydown', preventDefaultForScrollKeys, false);

		return () => {
			window.removeEventListener('DOMMouseScroll', preventDefault, false);
			window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
			window.removeEventListener('touchmove', preventDefault, wheelOpt);
			window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
		};
	}, [heroVisibility]);

	if (fromIndex) {
		return (
			<IndexHero
				isVisible={isVisible}
				isStackedVisible={isStackedVisible}
				allSanityArtwork={allSanityArtwork}
				onClick={() => setHeroVisibility(false)}
			/>
		);
	}
	return (
		<ProjectHero
			isVisible={isVisible}
			image={image}
			onClick={() => setHeroVisibility(false)}
			alt={alt}
		/>
	);
};

export default HeroImage;

function ProjectHero({ isVisible, image, onClick, alt }) {
	return (
		<div className={isVisible}>
			<GatsbyImage
				image={image}
				className="heroBackground"
				onClick={() => onClick()}
				alt={alt}
			/>
			<p className="scrollDown">
				scroll down
				<br />
				<i className="fa fa-chevron-down chevronAnimate"></i>
			</p>
		</div>
	);
}

function IndexHero({ isVisible, isStackedVisible, allSanityArtwork, onClick }) {
	const allSanityArtworkSliced = allSanityArtwork.slice(0, 5);
	const artworkCount = allSanityArtworkSliced.length;
	const artworkPresTime = 0.5;
	const artworkCrossDur = 4;
	const artworkAnimDur = (artworkPresTime + artworkCrossDur) * artworkCount;

	return (
		<div className={isStackedVisible}>
			<div className="heroStackedIntermediate">
				{allSanityArtworkSliced.map(
					(
						{
							node: {
								artwork: {
									alt,
									image: {
										asset: { gatsbyImageData },
									},
								},
							},
						},
						index
					) => (
						<GatsbyImage
							image={gatsbyImageData}
							//className={isStackedVisible}
							className="heroStacked"
							onClick={() => onClick()}
							alt={alt}
							key={index}
							style={{
								animationDelay: `${
									artworkAnimDur - (artworkPresTime + artworkCrossDur) * index
								}s`,
							}}
							//style={{ animationDelay: '4s' }}
						/>
					)
				)}
				<h1 id="heroHeader">ATLAS OF THE ESSENCE</h1>
				<p className="scrollDown">
					scroll down
					<br />
					<i className="fa fa-chevron-down chevronAnimate"></i>
				</p>
			</div>
		</div>
	);
}
