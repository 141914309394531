import React from 'react';
import clientConfig from '../../client-config';
import BlockContent from '@sanity/block-content-to-react';
import Serializers from './Serializers';

const PortableText = ({ blocks, className }) => (
	<BlockContent
		blocks={blocks}
		serializers={Serializers}
		className={className}
		{...clientConfig.sanity}
	/>
);

export default PortableText;
