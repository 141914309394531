//import * as React from 'react';
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import LanguageSelector from './LanguageSelector';

const NavBar = ({ pathName, locale, onClick /* , modalIsOpen  */ }) => {
	const [navVisibility, setNavVisibility] = useState(true);

	/* useEffect(() => {
		if (modalIsOpen) {
			setNavVisibility(false);
		} else {
			setNavVisibility(true);
		}
	}, [modalIsOpen]); */

	useEffect(() => {
		let prevScrollPos = window.pageYOffset;
		const handleScroll = () => {
			var currentScrollPos = window.pageYOffset;
			if (prevScrollPos > currentScrollPos && !navVisibility) {
				setNavVisibility(true);
			} else if (prevScrollPos < currentScrollPos && navVisibility) {
				setNavVisibility(false);
			}
			prevScrollPos = currentScrollPos;
		};

		document.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			// clean up the event handler when the component unmounts
			document.removeEventListener('scroll', handleScroll);
		};
	}, [navVisibility]);

	return (
		<div id="navBar" className={navVisibility ? '' : 'hidden'}>
			<div id="navContainer">
				{locale === 'de' ? (
					<Link to="/">
						<h1>Atlas of the Essence</h1>
					</Link>
				) : (
					<Link to="/en/">
						<h1>Atlas of the Essence</h1>
					</Link>
				)}
				<LanguageSelector pathName={pathName} locale={locale} />
				<nav>
					<ul id="navList">
						{/* <li>List</li> */}
						<li>
							<button
								onClick={() => {
									onClick();
								}}
								onKeyDown={() => {
									onClick();
								}}
							>
								Map
							</button>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
};

export default NavBar;
