import React from 'react';
import { navigate } from 'gatsby';

function LanguageSelector({ locale, pathName }) {
	if (locale === 'de') {
		return (
			<div>
				<button
					className="langSelector"
					onClick={() => {
						navigate('/en' + pathName, { state: { fromTranslation: true } });
					}}
				>
					en
				</button>
			</div>
		);
	} else {
		return (
			<div>
				<button
					className="langSelector"
					onClick={() => {
						navigate(pathName.replace('/' + locale + '/', '/'), {
							state: { fromTranslation: true },
						});
					}}
				>
					de
				</button>
			</div>
		);
	}
}
export default LanguageSelector;
