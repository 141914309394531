import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { StaticImage } from 'gatsby-plugin-image';

const Footer = ({ pageContext, fromIndex }) => {
	return (
		<footer>
			<ul>
				<li>
					<a
						role="button"
						aria-label="social media link to instagram"
						href="https://www.instagram.com/lisahoffmannstudio/li"
					>
						<FontAwesomeIcon icon={faInstagram} />
					</a>
				</li>
			</ul>
			<p>
				<a href="mailto:contact@lisa-hoffmann.de">contact@lisa-hoffmann.de</a>
				<br />
			</p>
			<p>
				<a href="https://www.lhoffmann.com">www.lhoffmann.com</a>
				<br />
			</p>
			<p>
				Copyright © 2021 Lisa Hoffmann.{' '}
				{pageContext.locale === 'en'
					? 'All rights reserved'
					: 'Alle Rechte vorbehalten'}
			</p>
			{fromIndex && (
				<div className="logoWrapper">
					<StaticImage
						src="../assets/AOTE_Logos_Neustart_Kultur@512.png"
						alt="Logo Neustarte Kultur"
						className="Logos"
					/>
					<StaticImage
						src="../assets/AOTE_Logos_Bundesverband_bildender_Kuenstlerinnen_und_Kuenstler@512.png"
						alt="Logo Bundesverband bildener Künstlerinnen und Künstler"
						className="Logos"
					/>
					<StaticImage
						src="../assets/AOTE_Logos_Bundesbeauftragte_fuer_Kultur_und_Medien@512.png"
						alt="Logo Beauftragte der Bundesregierung für Kultur und Medien"
						className="Logos"
					/>
				</div>
			)}
			<button className="scrollUp" onClick={() => scrollTo('#main')}>
				<FontAwesomeIcon icon={faChevronUp} />
				<br />
				{pageContext.locale === 'en' ? 'back to top' : 'nach oben'}
			</button>
		</footer>
	);
};

export default Footer;
