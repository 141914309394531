import '/src/styles/global.scss';
import '/src/styles/normalize.css';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import HeroImage from './HeroImage';
import NavBar from './NavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

//import 'leaflet/dist/leaflet.css';
import Map from '../components/Map';
import Footer from './Footer';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		//marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '16px',
		border: 'none',
	},
};

const Layout = ({ children, data, pageContext, location, fromIndex }) => {
	const isBrowser = typeof window !== 'undefined';
	if (isBrowser) {
		Modal.setAppElement(document.getElementById('root'));
	}

	const { pathname } = location;
	//const { state = fromTranslation } = location;
	const { locale } = pageContext;
	const allSanityArtwork = data.allSanityArtwork.edges;
	const { sanityArtwork = {} } = data;
	//console.log(sanityArtwork);
	const { location: artworkLocation = {}, _id, artwork = {} } = sanityArtwork;
	const { image = {}, alt } = artwork;
	const { asset = {} } = image;
	const { gatsbyImageData = {} } = asset;
	const { lat = 0, lng = 0 } = artworkLocation;
	//const { url = {} } = video;

	const [modalIsOpen, setIsOpen] = useState(false);

	const modalOverlayStyle = {
		base: 'modalOverlay',
		afterOpen: 'modalOverlayAfter',
		beforeClose: 'modalOverlayBefore',
	};

	function handleClick() {
		setIsOpen(true);
	}

	function closeModal() {
		setIsOpen(false);
	}

	useEffect(() => {
		//console.log(pageContext);
		//console.log(data.sanityArtwork.artwork.image.asset.gatsbyImageData.height);
		let vw = window.innerWidth * 0.01;
		let vh = window.innerHeight * 0.01;

		//console.log(window.innerHeight);
		//console.log(window.innerWidth);
		document.documentElement.style.setProperty('--vh', `${vh}px`);
		document.documentElement.style.setProperty('--vw', `${vw}px`);
		window.addEventListener('resize', () => {
			// We execute the same script as before
			let vh = window.innerHeight * 0.01;
			let vw = window.innerWidth * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
			document.documentElement.style.setProperty('--vw', `${vw}px`);
		});
	}, []);
	return (
		<div id="root">
			<Helmet>
				<link
					rel="stylesheet"
					href="https://use.typekit.net/rcl7iym.css"
				></link>
				<link
					rel="stylesheet"
					href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
				></link>
				<meta name="icon" href="/src/static/favicon.ico" />
			</Helmet>
			<HeroImage
				image={gatsbyImageData}
				allSanityArtwork={allSanityArtwork}
				location={location}
				alt={alt}
				fromIndex={fromIndex}
			/>
			<NavBar
				pathName={pathname}
				locale={locale}
				onClick={handleClick}
				/* modalIsOpen={modalIsOpen} */
			/>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				style={customStyles}
				//className="modal"
				overlayClassName={modalOverlayStyle}
				closeTimeoutMS={300}
			>
				<Map
					allSanityArtwork={allSanityArtwork}
					lat={lat}
					lng={lng}
					id={_id}
					fromIndex={fromIndex}
					locale={locale}
				/>
				<button onClick={closeModal}>
					<FontAwesomeIcon icon={faTimes} /> close
				</button>
			</Modal>
			{children}
			<Footer pageContext={pageContext} fromIndex={fromIndex} />
		</div>
	);
};

export default Layout;
