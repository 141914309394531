import React /* , { useEffect } */ from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import L from 'leaflet';
import {
	MapContainer as BaseMap,
	TileLayer,
	ZoomControl,
	Marker,
	Popup,
	/* useMap, */
} from 'react-leaflet';
import * as mapStyles from './Map.module.css';
/* import 'leaflet/dist/leaflet.css'; */
import icon from '../assets/marker-icon_2021_09_07_b.png';
import iconHighlight from '../assets/marker-icon-highlight_2021_09_07_a.png';
import iconShadow from '../assets/marker-shadow.png';
import iconRetina from '../assets/marker-icon-2x_2021_09_03_a.png';
import iconHighlightRetina from '../assets/marker-icon-highlight-2x_2021_09_03_a.png';

const isDomAvailable = typeof window !== 'undefined';
//console.log(icon);
if (isDomAvailable) {
	// To get around an issue with the default icon not being set up right between using React
	// and importing the leaflet library, we need to reset the image imports
	// See https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387

	/* delete L.Icon.Default.prototype._getIconUrl;

	L.Icon.Default.mergeOptions({
		iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
		iconUrl: require('leaflet/dist/images/marker-icon.png'),
		shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
	}); */

	let DefaultIcon = L.icon({
		iconUrl: icon,
		shadowUrl: iconShadow,
		iconRetinaURL: iconRetina,
		iconSize: [25, 33],
		iconAnchor: [12, 33],
		shadowAnchor: [14, 41],
		popupAnchor: [0, -33],
	});

	L.Marker.prototype.options.icon = DefaultIcon;
}

function MapInstancer({ children, lat, lng }) {
	//const mapInstance = useMap();

	/* useEffect(() => {
		const zoom = mapInstance.getZoom();
		//mapInstance.flyTo([lat, lng], 3, { duration: 2 });
		// do something with zoom
		//console.log(zoom);
	}, [mapInstance]); */
	return children;
}

const Map = ({
	children,
	allSanityArtwork,
	lat,
	lng,
	id,
	fromIndex,
	locale,
}) => {
	//console.log(mapInstance);

	let HighlightIcon = L.icon({
		iconUrl: iconHighlight,
		shadowUrl: iconShadow,
		iconRetinaURL: iconHighlightRetina,
		iconSize: [25, 33],
		iconAnchor: [12, 33],
		shadowAnchor: [14, 41],
		popupAnchor: [0, -33],
	});

	const mapSettings = {
		/* className: mapStyles.mapBase, */
		className: 'mapBase',
		zoomControl: false,
		//scrollWheelZoom: false,
		center: [lat, lng],
		zoom: 3,
		tap: false,
	};
	if (typeof window !== 'undefined') {
		return (
			<div className={mapStyles.map}>
				<BaseMap {...mapSettings}>
					<TileLayer
						url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
						attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ"
					/>
					<ZoomControl position="bottomright" />

					{!fromIndex && (
						<Marker position={[lat, lng]} icon={HighlightIcon}></Marker>
					)}
					{allSanityArtwork.map(({ node }) => (
						<MapInstancer
							key={node._id}
							lat={node.location.lat}
							lng={node.location.lng}
						>
							{node._id !== id && (
								<Marker position={[node.location.lat, node.location.lng]}>
									<Popup>
										{!fromIndex ? (
											<Link
												to={`../${node.slug.current}`}
												state={{ fromTranslation: false }}
											>
												<GatsbyImage
													className="popupImage"
													image={node.artwork.image.asset.gatsbyImageData}
													alt={node.artwork.alt}
												/>
												{/* <p className="popupTitle">{node.title}</p> */}
											</Link>
										) : locale === 'de' ? (
											<Link
												to={`/${node.slug.current}`}
												state={{ fromTranslation: false }}
											>
												<GatsbyImage
													className="popupImage"
													image={node.artwork.image.asset.gatsbyImageData}
													alt={node.artwork.alt}
												/>
												{/* <p className="popupTitle">{node.title}</p> */}
											</Link>
										) : (
											<Link
												to={`/en/${node.slug.current}`}
												state={{ fromTranslation: false }}
											>
												<GatsbyImage
													className="popupImage"
													image={node.artwork.image.asset.gatsbyImageData}
													alt={node.artwork.alt}
												/>
												{/* <p className="popupTitle">{node.title}</p> */}
											</Link>
										)}
									</Popup>
								</Marker>
							)}
						</MapInstancer>
					))}
					{children}
				</BaseMap>
			</div>
		);
	}
	return null;
};

Map.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	defaultBaseMap: PropTypes.string,
	mapEffect: PropTypes.func,
};

export default Map;
